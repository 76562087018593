const store = {
  namespaced: true,
  state: {
    videoIndex: 0,
    shortsVideoIndex: 0,
    shortsShowModal: false,
    hostAddressName: "https://티키앤타카.com",
    // hostAddressName: "http://티키타카.kr:81",
    rooms: [
      {
        title: "솔로 스튜디오",
        price: 21000,
        PriceToHour3: 65000,
        PriceToHour6: 117000,
        PriceToHour12: 221000,
        numPrice: 10000,
        numMin: 1,
        numMax: 5,
        imageSrc: require("@/assets/studio/studio7.svg"),
        studioNum: 1,
      },
      {
        title: "듀오 스튜디오",
        price: 23000,
        PriceToHour3: 70000,
        PriceToHour6: 126000,
        PriceToHour12: 238000,
        numPrice: 10000,
        numMin: 2,
        numMax: 5,
        imageSrc: require("@/assets/studio/studio7.svg"),
        studioNum: 2,
      },
      {
        title: "하이브리드 스튜디오",
        price: 31000,
        PriceToHour3: 95000,
        PriceToHour6: 171000,
        PriceToHour12: 323000,
        numPrice: 10000,
        numMin: 2,
        numMax: 7,
        imageSrc: require("@/assets/studio/하이브리드 스튜디오_1.png"),
        studioNum: 3,
      },
      {
        title: "워터 스튜디오",
        price: 26000,
        PriceToHour3: 80000,
        PriceToHour6: 144000,
        PriceToHour12: 272000,
        numPrice: 10000,
        numMin: 2,
        numMax: 5,
        imageSrc: require("@/assets/studio/워터 외부.png"),
        studioNum: 4,
      },
      {
        title: "화이트 스튜디오",
        price: 63000,
        numPrice: 10000,
        PriceToHour3: 190000,
        PriceToHour6: 342000,
        PriceToHour12: 646000,
        numMin: 4,
        numMax: 15,
        imageSrc: require("@/assets/studio/화이트 스튜디오.png"),
        studioNum: 5,
      },
      {
        title: "그린 스튜디오",
        price: 130000,
        PriceToHour3: 390000,
        PriceToHour6: 702000,
        PriceToHour12: 1326000,
        numPrice: 10000,
        numMin: 5,
        numMax: 15,
        imageSrc: require("@/assets/studio/그린 스튜디오.png"),
        studioNum: 6,
      },
    ],

    equipments: {
      camera: [
        {
          name: "SONY FX3",
          price: 10000,
          PriceToHour3: 30000,
          PriceToHour6: 54000,
          PriceToHour12: 102000,
          src: require("@/assets/studio/촬영장비/FX3.svg"),
          count: 1,
          equipmentNum: 1,
        },
        {
          name: "SONY A7M IV",
          price: 7000,
          PriceToHour3: 20000,
          PriceToHour6: 36000,
          PriceToHour12: 68000,
          src: require("@/assets/studio/촬영장비/A7M4.svg"),
          count: 2,
          equipmentNum: 2,
        },
        {
          name: "SONY FE 28-200mm F2.8 GM",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          src: require("@/assets/studio/촬영장비/28-200.svg"),
          count: 1,
          equipmentNum: 3,
        },
        {
          name: "SONY FE 24-70mm F2.8 GM",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          src: require("@/assets/studio/촬영장비/24-70.svg"),
          count: 1,
          equipmentNum: 4,
        },
        {
          name: "SONY FE 85mm F1.4 GM",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          src: require("@/assets/studio/촬영장비/85.svg"),
          count: 1,
          equipmentNum: 5,
        },
        {
          name: "탐론 28-200mm F2.8-5.6 Di lll RXD A071 SONY FE마운트",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          src: require("@/assets/studio/촬영장비/28-200.svg"),
          count: 2,
          equipmentNum: 6,
        },
        {
          name: "SONY NP-FZ100 배터리",
          price: 0,
          PriceToHour3: 0,
          PriceToHour6: 0,
          PriceToHour12: 0,
          src: require("@/assets/studio/촬영장비/FZ100.svg"),
          count: 3,
          equipmentNum: 7,
        },
        {
          name: "SONY FZ100 상시전원 더미 배터리 어댑터",
          price: 0,
          PriceToHour3: 0,
          PriceToHour6: 0,
          PriceToHour12: 0,
          count: 3,
          equipmentNum: 8,
        },
        {
          name: "K&f Concept NANO-X Ultra slim MRC UV 렌즈 필터 67mm",
          price: 0,
          PriceToHour3: 0,
          PriceToHour6: 0,
          PriceToHour12: 0,
          src: require("@/assets/studio/촬영장비/67mm.svg"),
          count: 2,
          equipmentNum: 9,
        },
        {
          name: "K&f Concept NANO-X Ultra slim MRC UV 렌즈 필터 77mm",
          price: 0,
          PriceToHour3: 0,
          PriceToHour6: 0,
          PriceToHour12: 0,
          src: require("@/assets/studio/촬영장비/77mm.svg"),
          count: 2,
          equipmentNum: 10,
        },
        {
          name: "K&f Concept NANO-X Ultra slim MRC UV 렌즈 필터 82mm",
          price: 0,
          PriceToHour3: 0,
          PriceToHour6: 0,
          PriceToHour12: 0,
          src: require("@/assets/studio/촬영장비/82mm.svg"),
          count: 1,

          equipmentNum: 11,
        },

        {
          name: "틸타 SONY FX3 케이지 베이직 키트 (TA-T13-A-B)",
          price: 5000,
          PriceToHour3: 15000,
          PriceToHour6: 54000,
          PriceToHour12: 102000,
          src: require("@/assets/studio/촬영장비/FX3 KIT.svg"),
          count: 1,
          equipmentNum: 13,
        },
        {
          name: "스몰리그 SR1989 삼각대 (헤드포함)",
          price: 0,
          PriceToHour3: 0,
          PriceToHour6: 0,
          PriceToHour12: 0,
          count: 3,
          equipmentNum: 14,
        },
        {
          name: "DJI 로닌 RS3 프로 콤보 짐벌",
          price: 7000,
          PriceToHour3: 20000,
          PriceToHour6: 36000,
          PriceToHour12: 68000,
          src: require("@/assets/studio/촬영장비/RS3프로콤보.svg"),
          count: 1,
          equipmentNum: 15,
        },
      ],
      monitor: [
        {
          name: "ATOMOS 난자 V 프리뷰모니터 프로 키트",
          price: 10000,
          PriceToHour3: 30000,
          PriceToHour6: 54000,
          PriceToHour12: 102000,
          src: require("@/assets/studio/촬영장비/난자V.svg"),
          count: 1,
          equipmentNum: 12,
        },
      ],
      MicAudio: [
        {
          name: "RODE VIDEO MIC PRO PLUS",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          src: require("@/assets/studio/촬영장비/샷건.svg"),
          count: 1,
          equipmentNum: 16,
        },
        {
          name: "SONY UWP-D21",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          count: 1,
          equipmentNum: 17,
        },
        {
          name: "코미카 4채널 무선마이크 BOOMX-U-QUA (송신기4, 수신기1)",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          count: 1,
          equipmentNum: 18,
        },
        {
          name: "베링거 FLOW8",
          price: 10000,
          PriceToHour3: 30000,
          PriceToHour6: 54000,
          PriceToHour12: 102000,
          count: 1,
          equipmentNum: 19,
        },
        {
          name: "Blackmagic Design ATEM Mini Extremem SIO",
          price: 10000,
          PriceToHour3: 30000,
          PriceToHour6: 54000,
          PriceToHour12: 102000,
          src: require("@/assets/studio/촬영장비/스위처.svg"),
          count: 1,
          equipmentNum: 20,
        },
      ],
      LightSubFilm: [
        {
          name: "고프로 히어로11 블랙 올인원 패키지",
          price: 6000,
          PriceToHour3: 20000,
          PriceToHour6: 36000,
          PriceToHour12: 68000,
          src: require("@/assets/studio/촬영장비/고프로.svg"),
          count: 1,
          equipmentNum: 21,
        },
        {
          name: "난라이트 파보튜브II 30X LED조명 2키트",
          price: 6000,
          PriceToHour3: 20000,
          PriceToHour6: 36000,
          PriceToHour12: 68000,
          src: require("@/assets/studio/촬영장비/난라이트.svg"),
          count: 1,
          equipmentNum: 22,
        },
        {
          name: "난라이트 파보튜브II 플로어스탠드",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          src: require("@/assets/studio/촬영장비/파보튜브스탠드.svg"),
          count: 2,
          equipmentNum: 23,
        },
        {
          name: "난라이트 FS-300B",
          price: 3000,
          PriceToHour3: 10000,
          PriceToHour6: 18000,
          PriceToHour12: 34000,
          count: 2,
          equipmentNum: 24,
        },
      ],
    },
  },
  mutations: {
    setShortsVideoNumber(state, value) {
      state.shortsVideoIndex = value;
    },
    setShortsShowModal(state, value) {
      state.shortsShowModal = value;
    },
  },
  actions: {
    setShortsVideoNumber({ commit }, value) {
      commit("setShortsVideoNumber", value);
    },
    setShortsShowModal({ commit }, value) {
      commit("setShortsShowModal", value);
    },
  },
  getters: {
    shortsVideoIndex: (state) => state.shortsVideoIndex,
    shortsShowModal: (state) => state.shortsShowModal,
    hostAddressName: (state) => state.hostAddressName,
    rooms: (state) => state.rooms,
    equipments: (state) => state.equipments,
    news: (state) => state.news,
  },
};

export default store;
